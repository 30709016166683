var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('JobsStyled', [_c('ControlsStyled', [_c('router-link', {
    attrs: {
      "to": {}
    }
  }, [_vm._v("Planned")]), _c('router-link', {
    attrs: {
      "to": {
        query: {
          jobType: 'FAILED'
        }
      }
    }
  }, [_vm._v("Failed")]), _c('router-link', {
    attrs: {
      "to": {
        query: {
          jobType: 'COMPLETED'
        }
      }
    }
  }, [_vm._v("Completed")]), _c('router-link', {
    attrs: {
      "to": {
        query: {
          jobType: 'ACTIVE'
        }
      }
    }
  }, [_vm._v("Active")]), _c('Multiselect', {
    attrs: {
      "options": [10, 20, 50, 100, 200],
      "hideSelected": true,
      "allowEmpty": false,
      "showLabels": false,
      "placeholder": "Per page: ".concat(_vm.perPage)
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(_ref) {
        var option = _ref.option;
        return [_c('span', [_vm._v("Per page: ")]), _vm._v(" " + _vm._s(option) + " ")];
      }
    }]),
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1), _c('PanelTableMolecule', {
    staticClass: "jobs-table",
    attrs: {
      "isLoading": _vm.loading,
      "count": _vm.totalCount,
      "title": _vm._f("capitalize")(_vm.$tc('job', 2)),
      "columns": _vm.columns,
      "rows": _vm.tableData,
      "pageIndex": _vm.page,
      "pageSize": _vm.perPage,
      "selectedRow": _vm.deleteRow
    },
    on: {
      "pageNumberChange": function pageNumberChange($event) {
        return _vm.pageNumberChange($event);
      },
      "reload": _vm.reload
    }
  }), _c('div', {
    staticClass: "misc"
  }, [_c('router-link', {
    staticClass: "btn",
    attrs: {
      "to": {
        name: 'RescheduleJobs'
      }
    }
  }, [_vm._v("Reschedule Jobs")])], 1), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }