<template>
  <JobsStyled>
    <ControlsStyled>
      <router-link :to="{}">Planned</router-link>
      <router-link :to="{ query: { jobType: 'FAILED' } }">Failed</router-link>
      <router-link :to="{ query: { jobType: 'COMPLETED' } }">Completed</router-link>
      <router-link :to="{ query: { jobType: 'ACTIVE' } }">Active</router-link>
      <Multiselect
        v-model="perPage"
        :options="[10, 20, 50, 100, 200]"
        :hideSelected="true"
        :allowEmpty="false"
        :showLabels="false"
        :placeholder="`Per page: ${perPage}`"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <span>Per page: </span>
          {{ option }}
        </template>
      </Multiselect>
    </ControlsStyled>
    <PanelTableMolecule
      class="jobs-table"
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('job', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page"
      :pageSize="perPage"
      :selectedRow="deleteRow"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <div class="misc">
      <router-link class="btn" :to="{ name: 'RescheduleJobs' }">Reschedule Jobs</router-link>
    </div>
    <router-view />
  </JobsStyled>
</template>

<script>
import get from 'lodash/get'
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { styled } from '@egoist/vue-emotion'
import { Edit2Icon, TrashIcon } from 'vue-feather-icons'
import { DateTime } from 'luxon'

import { getRolesFromToken } from '@common/utils'
import { buttonReset, flexCenter } from '@styles/mixins'

import LIST_JOBS_QUERY from '#/graphql/calendar/job/list.gql'

import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'
import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'

const JobsStyled = styled('div')`
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  .misc {
    display: flex;
    justify-content: flex-end;
  }
  .jobs-table {
    position: relative;
    z-index: 10;
  }
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${({ theme }) => theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

const ControlsStyled = styled('aside')`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 2fr;
  grid-template-rows: min-content;
  grid-gap: 1rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  z-index: 100;

  > a {
    ${buttonReset}
    ${flexCenter}
    color: ${({ theme }) => theme.colors.white};
    background: #141414;
    padding: 0.5rem;
    border-radius: 0.5rem;
    &.router-link-exact-active {
      background: ${({ theme }) => theme.colors.primary};
    }
  }
  > .multiselect {
    position: relative;
    width: 10rem;
    z-index: 1000;
    * {
      z-index: 1010;
    }
    > .multiselect__tags {
      ${flexCenter}
      border-radius: 0.5rem;
      padding: 2px 40px 2px 0.5rem;
      .multiselect__single,
      .multiselect__input {
        margin-bottom: 0;
      }
    }
    > .multiselect__content-wrapper {
      border-bottom: none;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    &.multiselect--active {
      > .multiselect__tags {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    jobType: {
      type: String,
      required: true,
    },
  },
  components: {
    JobsStyled,
    PanelTableMolecule,
    ControlsStyled,
    Multiselect,
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchID: '',
      where: undefined,
      perPage: 10,
      page: 1,
      deleteRow: null,
    }
  },
  computed: {
    isJobTypePlanned() {
      return this.jobType === 'PLANNED'
    },
    modelsMapped() {
      return this.models
        .map(m => ({
          ...m,
          ownerName: m?.schedule?.calendar?.owner?.name || '-',
          calendarName: m?.schedule?.calendar?.name || '-',
          scheduleName: m?.schedule?.name || '-',
        }))
        .sort((a, b) => {
          return DateTime.fromISO(b.runAt).toMillis() - DateTime.fromISO(a.runAt).toMillis()
        })
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'job'
      } else {
        return null
      }
    },
    createRoute() {
      if (this.hasWritePermission) {
        return 'createJob'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'id',
          key: 'id',
          title: 'ID',
          align: 'left',
          renderBodyCell: ({ row }) => {
            return <EntityLinkAtom type="Job" id={row.id} label={row.id} />
          },
        },
        {
          field: 'calendar',
          key: 'calendar',
          title: 'Calendar',
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row?.schedule?.calendar) {
              return `-`
            }
            const { id, __typename, name } = row?.schedule?.calendar
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        {
          field: 'scheduleName',
          key: 'scheduleName',
          title: 'Schedule',
          align: 'center',
        },
        {
          field: 'owner',
          key: 'owner',
          title: `${this.$tc('owner', 1)}`,
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row?.owner || !row.owner?.id) {
              return `-`
            }
            const { id, type, label } = row?.owner
            if (!id || !type || !label) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={label} />
          },
        },
        {
          field: 'createdAt',
          key: 'createdAt',
          title: 'Created At',
          align: 'center',
        },
        {
          field: 'runAt',
          key: 'runAt',
          title: 'Run At',
          align: 'center',
        },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <div on-click={() => this.delete(row)}>
                  <TrashIcon size="1.3x" />
                </div>
                <div on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}>
                  <Edit2Icon size="1.3x" />
                </div>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.modelsMapped.map(job => {
        return {
          id: job.id,
          createdAt: this.$options.filters.formatDateTime(job.createdAt),
          runAt: this.$options.filters.formatDateTime(job.runAt),
          calendar: job.schedule?.calendar,
          schedule: job.schedule,
          scheduleName: job.schedule?.name,
          ownerType: job.ownerName,
          owner: {
            type: job?.schedule?.calendar?.owner?.__typename,
            id: job?.schedule?.calendar?.owner?.id,
            label: job?.schedule?.calendar?.owner?.name,
          },
          rowKey: job.id,
          type: job.__typename,
        }
      })
      return tableData
    },
    locale() {
      return get(this.uiSettings, 'dates').toLowerCase().replace('_', '-')
    },
    availableOwnerTypes() {
      return [
        {
          value: 'Organization',
          label: 'Organization',
          selected: false,
        },
        {
          value: 'Subsidiary',
          label: 'Subsidiary',
          selected: false,
        },
        {
          value: 'User',
          label: 'User',
          selected: false,
        },
      ]
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      this.page = pageIndex
    },
    reload() {
      this.$apollo.queries.models.refetch()
    },
    delete(row) {
      this.deleteRow = row
    },
  },
  apollo: {
    models: {
      query: LIST_JOBS_QUERY,
      variables() {
        const start = this.perPage * (this.page - 1)
        const end = start + this.perPage
        const where = {
          start,
          end,
          types: [this.jobType],
        }
        return {
          where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = data?.jobs
        this.totalCount = this.models.length
        this.count = this.models.length
      },
    },
  },
}
</script>
